//Fonts

//Fonts
// @font-face {
//     font-family: 'palanquinbold';
//     src: url('../webfonts/palanquin-bold-webfont.eot');
//     src: url('../webfonts/palanquin-bold-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../webfonts/palanquin-bold-webfont.woff2') format('woff2'),
//          url('../webfonts/palanquin-bold-webfont.woff') format('woff'),
//          url('../webfonts/palanquin-bold-webfont.ttf') format('truetype'),
//          url('../webfonts/palanquin-bold-webfont.svg#palanquinbold') format('svg');
//     font-weight: normal;
//     font-style: normal;
//
// }

// @font-face {
//     font-family: 'palanquinsemibold';
//     src: url('../webfonts/palanquin-semibold-webfont.eot');
//     src: url('../webfonts/palanquin-semibold-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../webfonts/palanquin-semibold-webfont.woff2') format('woff2'),
//          url('../webfonts/palanquin-semibold-webfont.woff') format('woff'),
//          url('../webfonts/palanquin-semibold-webfont.ttf') format('truetype'),
//          url('../webfonts/palanquin-semibold-webfont.svg#palanquinsemibold') format('svg');
//     font-weight: normal;
//     font-style: normal;
//
// }

@font-face {
    font-family: 'akzidenz-grotesk-bold';
    src: url('../webfonts/akzidgrostdbol-webfont.eot');
    src: url('../webfonts/akzidgrostdbol-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdbol-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdbol-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdbol-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdbol-webfont.svg#akzidenz-grotesk_stdbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'akzidenz-grotesk-bold-italic';
    src: url('../webfonts/akzidgrostdbolita-webfont.eot');
    src: url('../webfonts/akzidgrostdbolita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdbolita-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdbolita-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdbolita-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdbolita-webfont.svg#akzidenz-grotesk_stdBdIt') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'akzidenz-grotesk-regular';
    src: url('../webfonts/akzidgrostdreg-webfont.eot');
    src: url('../webfonts/akzidgrostdreg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdreg-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdreg-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdreg-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdreg-webfont.svg#akzidenz-grotesk_std_regulaRg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'akzidenz-grotesk-regular-italic';
    src: url('../webfonts/akzidgrostdita-webfont.eot');
    src: url('../webfonts/akzidgrostdita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdita-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdita-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdita-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdita-webfont.svg#akzidenz-grotesk_std_regulaIt') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'akzidenz-grotesk-light';
    src: url('../webfonts/akzidgrostdlig-webfont.eot');
    src: url('../webfonts/akzidgrostdlig-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdlig-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdlig-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdlig-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdlig-webfont.svg#akzidenz-grotesk_stdlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'akzidenz-grotesk-light-italic';
    src: url('../webfonts/akzidgrostdligita-webfont.eot');
    src: url('../webfonts/akzidgrostdligita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdligita-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdligita-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdligita-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdligita-webfont.svg#akzidenz-grotesk_stdLtIt') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'akzidenz-grotesk-medium';
    src: url('../webfonts/akzidgrostdmed-webfont.eot');
    src: url('../webfonts/akzidgrostdmed-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdmed-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdmed-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdmed-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdmed-webfont.svg#akzidenz-grotesk_stdmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'akzidenz-grotesk-medium-italic';
    src: url('../webfonts/akzidgrostdmedita-webfont.eot');
    src: url('../webfonts/akzidgrostdmedita-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/akzidgrostdmedita-webfont.woff2') format('woff2'),
         url('../webfonts/akzidgrostdmedita-webfont.woff') format('woff'),
         url('../webfonts/akzidgrostdmedita-webfont.ttf') format('truetype'),
         url('../webfonts/akzidgrostdmedita-webfont.svg#akzidenz-grotesk_stdMdIt') format('svg');
    font-weight: normal;
    font-style: normal;
}



// Import Custom Variables (Overrides Default Bootstrap Variables)
@import "variables.scss";

// Import Bootstrap
@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

// Import Custom Mixins and Components
@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";

// Custom Components
@import "header.scss";
@import "navs.scss";
@import "footer.scss";
@import "typography.scss";
@import "forms.scss";
@import "buttons.scss";
@import "columns.scss";
@import "dropdowns.scss";
@import "accordions.scss";
@import "cards.scss";
@import "charts.scss";
@import "tabs.scss";
@import "team.scss";
@import "modals.scss";
@import "tooltip.scss";
@import "backgrounds.scss";
@import "tables.scss";
@import "car-animation.scss";
@import "print.scss";
@import "landing.scss";
@import "landing-2.scss";
@import "landing-3.scss";
@import "landing-5.scss";
@import "landing-interactive-paper.scss";
