.card-header, .card-body, .card-footer {
	border: 0;
}
.card-footer {
	background: transparent;
}

a.card {
	text-decoration: none;
	color: inherit;
}

a.card-download-pdf {
	p.card-title {
		font-size: 16px;
	}

	.icon-folder {
		height: 66.66px;
		width: auto;
		@include media-breakpoint-up(xl) {
			height: 134px;
			width: auto;
			max-width: 168px;
		}
	}
}

.card-1 {
	.card-body {
		p {
			font-size: 16px;
			line-height: 170%;
			// @include media-breakpoint-up(xl) {
			// 	font-size: 16px;
			// }
		}
	}
}

.card-2 {
	.card-body {
		h6 {
			font-size: 16px;
		}
	}
}
