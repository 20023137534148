// Global component styles

html {
	position: relative;
	min-height: 100%;
	overflow-y: scroll;
}

body {
	height: 100%;
	color: $gsk-dark-grey;
	font-weight: 400;
	font-family: 'akzidenz-grotesk-regular', arial, sans-serif;
}

a {
	color: $gsk-teal;
	transition: all 0.3s;

	&:hover {
		color: $gsk-primary;
	}

	&:focus {
		outline: none;
	}
}

#wrapper {
	// display: flex;
	#content-wrapper {
		min-height: 100vh;

		#content {
			flex: 1 0 auto;
		}
	}
}

.container {
	@include media-breakpoint-only(md) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include media-breakpoint-only(lg) {
		padding-left: 24px;
		padding-right: 24px;
	}
}
// Scroll to top button

.scroll-to-top {
	position: fixed;
	right: 0.5rem;
	bottom: 0.5rem;
	display: none;
	width: 2.75rem;
	height: 2.75rem;
	text-align: center;
	color: $white;
	background: fade-out($grey, .5);
	line-height: 46px;

	&:focus,
	&:hover {
		color: $white;
	}

	&:hover {
		background: $grey;
	}

	i {
		font-weight: 800;
	}
}

.bg-gradient-orange {
	background: linear-gradient(67.38deg, #E42313 18.76%, #FFDA00 87.91%);
}

.bg-gradient-grey {
	background: linear-gradient(67.38deg, #64564E 18.76%, #B9B3AE 87.91%);
}

.bg-gradient-purple {
	background: linear-gradient(67.38deg, #312779 18.76%, #E8308A 87.91%);
}

.bg-gradient-red {
	background: linear-gradient(67.38deg, #E4003B 18.76%, #EB5B8C 87.91%);
}

.bg-gradient-green {
	background: linear-gradient(67.38deg, #00893E 18.76%, #E4E023 87.91%);
}

.bg-gradient-teal {
	background: linear-gradient(67.38deg, #006B77 18.76%, #00B4CD 87.91%);
}

.bg-gradient-teal-30 {
	background: linear-gradient(67.38deg, rgba(0, 107, 119, 0.3) 18.76%, rgba(0, 180, 205, 0.3) 87.91%);
}
/* Effect styles */

.shadow-sm,
.shadow-small {
	box-shadow: 0 0 8px rgba(84, 79, 64, 0.06) !important;
}

.shadow,
.shadow-medium {
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.15) !important;
}

.shadow-large,
.shadow-lg {
	box-shadow: 0 2px 75px rgba(0, 0, 0, 0.15) !important;
}

section.hero-header-1 {
	padding-top: 128px;
	padding-bottom: 0;
	@include media-breakpoint-up(md) {
		padding-top: 188px;
		padding-bottom: 0;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 192px;
		padding-bottom: 0;
	}
}

.hero-header-1-inner {
	background: $white-85;
	padding: 40px 24px;
	@include media-breakpoint-up(md) {
		padding: 40px 24px;
	}
	@include media-breakpoint-up(lg) {
		padding: 48px 64px;
	}
}

.section-inner {
	padding: 40px 24px;
	@include media-breakpoint-up(md) {
		padding: 40px 24px;
	}
	@include media-breakpoint-up(lg) {
		padding: 48px 64px;
	}
}

section.hero-header-2 {
	padding-top: 12px;
	@include media-breakpoint-up(md) {
		padding-top: 18px;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 24px;
	}
}

section.hero-header-2 h2 + p {
	margin-top: -16px;
}

section.padded-section {
	padding-top: 48px;
	padding-bottom: 60px;
	@include media-breakpoint-up(md) {
		padding-top: 60px;
		padding-bottom: 72px;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 72px;
		padding-bottom: 96px;
	}
}

section.next-steps {
	text-align: center;

	.next-steps-inner {
		@include media-breakpoint-up(sm) {
			max-width: 472px;
			margin-left: auto;
			margin-right: auto;
		}
		@include media-breakpoint-up(lg) {
			max-width: 976px;
		}
		background: $white-85;
		padding: 40px 24px;
		@include media-breakpoint-up(md) {
			padding: 40px 24px;
		}
		@include media-breakpoint-up(lg) {
			padding: 48px 64px;
		}
	}
}

.number-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border-style: solid;
	border-width: 4px;
	font-size: 32px;
	text-align: center;
	color: $gsk-primary;
	line-height: 32px;
	font-family: 'akzidenz-grotesk-medium', arial, sans-serif;
	@include media-breakpoint-up(md) {
		width: 60px;
		height: 60px;
		border-width: 5px;
		font-size: 42px;
		line-height: 42px;
	}
}

.stat-title {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 50%;
	@include media-breakpoint-up(sm) {
		position: relative;
		top: 0;
		right: 0;
		width: auto;
	}

	h3 {
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		@include media-breakpoint-up(sm) {
			display: block;
			font-size: 26px;
		}
	}

	.icon {
		display: inline-block;
		float: right;
		width: 30px;
		vertical-align: middle;
		@include media-breakpoint-up(sm) {
			display: block;
			float: none;
		}
	}
}

.stat-box {
	display: block;
	margin-top: 2rem;
	border-left: 3px solid $gsk-primary;
	@include media-breakpoint-up(sm) {
		width: 85%;
	}

	.stat-value {
		font-family: 'akzidenz-grotesk-medium', arial, sans-serif;
		font-size: 44px;
		line-height: 44px;
	}
}

.stacked-breadcrumbs {
	margin-top: -84px;
	@include media-breakpoint-up(md) {
		margin-top: -92px;
	}
}

.stacked-breadcrumb-item {
	position: relative;
	display: flex;
}

.stacked-breadcrumb-item:last-child .stacked-breadcrumb-dot:after {
	display: none;
}

.stacked-breadcrumb-dot {
	position: relative;
	border-color: $gsk-dark-grey;
}

.stacked-breadcrumb-dot:before {
	content: '';
	background-image: url("/assets/img/icons/icon_breadcrumbtick.svg");
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	width: 13px;
	height: 13px;
	top: 8px;
	left: 50%;
	transform: translateX(-50%);
}

.stacked-breadcrumb-dot:after {
	content: '';
	position: absolute;
	border-color: inherit;
	border-width: 1px;
	border-style: dashed;
	border-radius: 50%;
	width: 13px;
	height: 13px;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: auto;
	top: 13px;
	bottom: -8px;
	border-right-width: 0;
	border-top-width: 0;
	border-bottom-width: 0;
	border-radius: 0;
}

.stacked-breadcrumb-item.active .stacked-breadcrumb-dot:after,
.stacked-breadcrumb-item.active .stacked-breadcrumb-dot:before {
	content: '';
	background-image: none;
	position: absolute;
	border-color: inherit;
	border-width: 2px;
	border-style: solid;
	border-radius: 50%;
	width: 19px;
	height: 19px;
	top: 8px;
	left: 50%;
	transform: translateX(-50%);
}

.stacked-breadcrumb-item h4 {
	font-size: 22px;
	line-height: 130%;
}


.text-divider {
  display: flex;
  align-items: center;
  text-align: center;
	max-width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.text-divider::after,
.text-divider::before {
  content: "";
  border: 1px solid $white;
  flex: 1;
}

.text-divider:not(:empty)::before {
  margin-right: 0.5em;
}

.text-divider:not(:empty)::after {
  margin-left: 0.5em;
}


.arrow-container {
	position: relative;
}
.arrow {
	position: relative;
	background-color: $gsk-primary;
	&.horizontal {
		height: 1px;
	}
	&.vertical {
		// height: calc(100% - 3px);
		width: 1px;
		height: 100%;
	}
}

.vertical-legend {
	position: absolute;
	top: 45%;
	transform: translateX(0.75rem) rotate(90deg);
  width: 100%;
	display: inline-block;
	white-space: nowrap;
}

.arrow::after {
	position: absolute;
	content: "";
  border: solid $gsk-primary;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
}

.right.arrow::after  {
	right: 0;
	top: -2px;
  transform: rotate(-45deg);
}

.left.arrow::after  {
	left: 0;
	top: -2px;
  transform: rotate(135deg);
}

.up.arrow::after  {
	top: 0;
	left: -2px;
  transform: rotate(-135deg);
}

.down.arrow::after  {
	bottom: 0;
	left: -2px;
  transform: rotate(45deg);
}

video {
  width: 100%;
  height: auto;
}
