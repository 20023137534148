.card.accordion-card-1 {
	border-radius: 0;
	border: 1px dashed $gsk-dark-grey;
	border-left: 3px solid $gsk-primary;
	background-color: transparent;

	.card-header {
		border: 0;
		background-color: transparent;
		cursor: pointer;
		background-image: url("/assets/img/icons/chevron_up.svg");
		background-repeat: no-repeat;
		background-position: center right 1rem;
		background-size: auto;

		&.collapsed {
			background-image: url("/assets/img/icons/chevron_down.svg");
		}

		h5 {
			text-transform: uppercase;
			color: $gsk-teal;
			font-family: 'akzidenz-grotesk-medium', arial, sans-serif;
			letter-spacing: 0.08em;
		}
	}
}

.card.accordion-card-2 {
	border-radius: 0;
	border: 0;
	background-color: transparent;

	.card-header {
		border: 0;
		background-color: $surface-medium;
		cursor: pointer;
		background-image: url("/assets/img/icons/chevron_up_teal.svg");
		background-repeat: no-repeat;
		background-position: center right 1rem;
		background-size: auto;

		&.collapsed {
			background-image: url("/assets/img/icons/chevron_down_teal.svg");
		}

		h4 {
			color: $gsk-teal;
			font-family: 'akzidenz-grotesk-medium', arial, sans-serif;
		}
	}
}


.toggle-more {
	padding-bottom: 2rem;
	background-image: url("/assets/img/icons/chevron_up.svg");
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: auto;

	&.collapsed {
		background-image: url("/assets/img/icons/chevron_down.svg");
	}
}
