// Pseudo Line Utilities

@each $color, $value in $theme-colors {
    .before-line-#{$color} {
			&::before {
				content: "";
				width: 50px;
				height: 3px;
				display: block;
				background-color: $value !important;
				margin-bottom: 12px;
			}
    }
}

@each $color, $value in $theme-colors {
    .after-full-line-#{$color} {
			&::after {
				content: "";
				width: 100%;
				height: 3px;
				display: block;
				background-color: $value !important;
				margin-top: 12px;
			}
    }
}
