footer#footer {
  // flex-shrink: 0;
  display: block;
  width: 100%;

  @include media-breakpoint-up(lg) {
  .container {
    max-width: 1008px;
    margin-left: auto;
    margin-right: auto;
  }
}

  h4 {
    margin: 0;
  }

  li {
    margin: 0;
  }

  a.nav-link {
    padding: 0;
  }
  #footer-prescribing-info-links {
    li {
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md) {
        padding-right: 3.0rem;
      }
    }
  }
  #footer-legal-links {
    a.nav-link {
      padding: 0.25rem 1.0rem;
    }
 }
}

@include media-breakpoint-up(xl) {
#footer-links .container {
  position: relative;
  &::before {
  content: "";
  display: block;
  width: 57px;
  // height: 50px;
  height: 57px;
  background-image: url('../img/gsk-logo.png');
  background-repeat: no-repeat;
  // background-size: 56.85px 50px;
  background-size: 57px auto;
  // background-position: left center;
  background-position: top left;
  position: absolute;
  left: -68px;
  // top: 72px;
  top: 78px;
  }
}
}

#footer-adverse-events .medium {
  font-size: 18px;
}
