.bg-landing-image-noshadow {
	background-image: url("/uploads/landing/landing-bg-small_noshadow.jpg");
	background-position: top center;
	background-size: 100%;
	background-repeat: no-repeat;
		@include media-breakpoint-up(md) {
				background-image: url("/uploads/landing/landing-bg-large_noshadow.jpg");
		}
}

.bg-landing-image-shadow {
	background-image: url("/uploads/landing/landing-bg-small_shadow.jpg");
	background-position: top center;
	background-size: 100%;
	background-repeat: no-repeat;
		@include media-breakpoint-up(md) {
				background-image: url("/uploads/landing/landing-bg-large_shadow.jpg");
		}
}

.landing-content {
	margin-top: 270px;
	@include media-breakpoint-up(sm) {
		  margin-top: 40vw;
	}
	@include media-breakpoint-up(md) {
		  margin-top: 30vw;
	}
	@include media-breakpoint-up(lg) {
		  margin-top: 30vw;
	}
	@include media-breakpoint-up(xl) {
		  margin-top: 30vw;
	}
}


ul.landing-bullets {
	padding-left: 1rem;
	li::marker {
		color: $landing-2;
	}
	li {
		margin-bottom: 0.5rem;
	}
	li:last-child {
		margin-bottom: 0;
	}
}

.landing-rounded {
	border-radius: 20px;
}
