#landing-3-hero-image {

	 @include media-breakpoint-up(sm) {
max-width: 470px;
	 }
	 @include media-breakpoint-up(md) {
		 max-width: 600px;
		 float: right;
	 }
	 @include media-breakpoint-up(lg) {
		 max-width: 470px;
		 margin-left: -280px;
		 margin-top: 20px;
		 margin-bottom: -160px;
	 }
}

.img-240 {
	width: 240px;
}

.background-gradient-orange {
	background: #f36633 linear-gradient(180deg, #fa4c03 0%, #ff7200 100%);
}
