// #header.sticky {
//   position: fixed;
//   top: 0;
//   width: 100%;
//   border-bottom: 1px solid $surface-medium;
// }

header#header {
	border-bottom: 1px solid $surface-medium;

	.navbar {
		padding: 0;

		a.nav-brand {
			display: inline-block;
			// width: 40px;
			// height: auto;
			height: 30px;
			width: auto;
			max-height: 30px;
			max-width: auto;
			margin: 0.5rem 0.5rem 0.5rem 1.25rem;
			transition: all 0.3s;
			@include media-breakpoint-up(md) {
				// width: 40px;
				height: 35px;
				max-height: 35px;
				margin: 0.5rem;
			}
			@include media-breakpoint-up(lg) {
				// width: 56.85px;
				height: 49.0333px;
				max-height: 49.0333px;
				margin-right: 1rem;
			}
			@include media-breakpoint-up(xl) {
				margin-left: 0;
			}
			img.img-fluid {
				max-height: 30px;
				@include media-breakpoint-up(md) {
					max-height: 35px;
				}
				@include media-breakpoint-up(lg) {
					margin-top: 8px;
					max-height: 35px;
					// max-height: 49.0333px;
				}
			}
		}

		.navbar-toggler {
			padding: 0.75rem;
			font-size: 1rem;
			line-height: 1;
			background-color: transparent;
			border: 1px solid transparent;
			border-radius: 0;
			border-left: 1px solid $surface-medium;

			&:focus,
			&:hover {
				background-color: $surface-light;
			}

			&[aria-expanded="false"] {
				.icon-menu {
					display: block;
				}

				.icon-close {
					display: none;
				}
			}

			&[aria-expanded="true"] {
				.icon-menu {
					display: none;
				}

				.icon-close {
					display: block;
				}
			}
		}

		ul.navbar-nav > .nav-item {
			border-left: 1px solid $surface-medium;

			.nav-link {
				padding: 0 0.75rem;
				line-height: 40px;
				color: $gsk-dark-grey;
				// @include media-breakpoint-up(lg) {
				// 	line-height: 67px;
				// }
				&:focus,
				&:hover {
					color: $gsk-dark-grey;
					background-color: $surface-light;
				}
			}

			&.active .nav-link {
				color: $gsk-dark-grey;
				background-color: $surface-light;
			}
		}

		#links.navbar ul.navbar-nav > .nav-item:last-child {
			@include media-breakpoint-up(lg) {
				border-right: 1px solid $surface-medium;
			}
		}
		@include media-breakpoint-down(sm) {
			.navbar-collapse.show .nav-item {
				border-top: 1px solid $surface-medium;
				border-left: 0;
				border-right: 0;

				.navbar-search input {
					width: 100%;
				}
			}
		}

		.navbar-search {
			.input-group * {
				border-radius: 0;
			}

			input.form-control {
				width: 80px;
				height: 40px;
				padding: 0.375rem 0.75rem;
				padding-right: 1.25rem;
				background-color: transparent;
				background-image: url("/assets/img/icons/icon_search.svg");
				background-repeat: no-repeat;
				background-position: center right 8px;
				background-size: 14px 14px;
				// &:focus {
				// 	// padding-right: 0.5rem;
				// 	// background-image: none;
				// }
				&:focus,
				&:hover {
					color: $gsk-dark-grey;
					background-color: $surface-light;
					box-shadow: none;
				}
			}
		}

		.dropdown-menu {
			padding: 0;
			margin: 0;
			font-size: 1rem;
			color: $gsk-dark-grey;
			border: 1px solid $surface-medium;
			border-radius: 0;
		}

		.dropdown-item {
			border-bottom: 1px solid $surface-medium;
			padding: 0.75rem;

			&:last-child {
				border-bottom: 0;
			}

			&:focus,
			&:hover {
				color: $gsk-dark-grey;
				background-color: $surface-light;
			}
		}

		#nav-loggedin ul.navbar-nav > .nav-item {
			border: 0;
			margin: 0;
			border: 0;

			.nav-link {
				padding: 3px 1.0rem 0;
				line-height: 42px;
				color: $white;
				border-bottom: 3px solid $gsk-primary;

				&:focus,
				&:hover {
					color: $white;
					background-color: $gsk-primary;
					border-bottom-color: $white;
				}
			}

			&.active .nav-link {
				color: $white;
				background-color: $gsk-primary;
				border-bottom-color: $white;
			}
		}

		#nav-admin {

			ul.navbar-nav > .nav-item {
			border: 0;
			margin: 0;
			border: 0;

			.nav-link {
				padding: 3px 1.0rem 0;
				line-height: 42px;
				color: $white;

				@include media-breakpoint-up(xl) {
				border-bottom: 3px solid $black;
			}

				&:focus,
				&:hover {
					color: $black;
					background-color: $white;
					@include media-breakpoint-up(xl) {
					color: $white;
					background-color: $black;
					border-bottom-color: $white;
				}
				}
			}

			&.active .nav-link {
				color: $black;
				background-color: $white;
				@include media-breakpoint-up(xl) {
					color: $white;
					background-color: $black;
					border-bottom-color: $white;
				}
			}
		}
	}
}
}

#nav-loggedin-wrap, #nav-admin-wrap  {
	@include media-breakpoint-up(md) {
		margin-bottom: -1px !important;
	}
}

header#header #admin-toggler.navbar-toggler {
	border: 0;

	&:focus,
	&:hover {
		background-color: transparent;
	}
}
