      .car-animation-wrapper {
        max-width: 960px;
        min-width: 200px;
        margin: 15px auto;
    }

    .car-animation {
        width: 100%;
        height: 0;
        padding-bottom: 25.90163934%;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
    }

    .car-animation div {
        position: absolute;
        background-repeat: no-repeat;
        animation-timing-function: linear;
        animation-duration: 6s;
        /* set animation duration here */
        animation-fill-mode: forwards;
    }

    .car-animation-route-1 {
        width: 92%;
        height: 100%;
        bottom: 0;
        left: 4%;
        background-image: url('/assets/img/car-animation/Route2.svg');
        background-size: auto 100%;
        background-position: bottom left;
    }

    .car-animation-route-2 {
        width: 0;
        height: 100%;
        bottom: 0;
        left: 4%;
        background-image: url('/assets/img/car-animation/Route1.svg');
        background-size: auto 100%;
        background-position: bottom left;
    }

    .play .car-animation-route-2 {
        animation-name: overlay;
    }

    @keyframes overlay {
        0% {
            width: 0%;
        }
        100% {
            width: 63.5%;
        }
    }

    .car-animation-line {
        bottom: 2%;
        left: 3.725%;
        width: 0.65%;
        height: 78%;
        background: #fff;
        border-radius: 30px;
    }

    .play .car-animation-line {
        animation-name: line;
    }

    @keyframes line {
        0% {
            left: 4%;
        }
        100% {
            left: 67.225%;
        }
    }

    .car-animation-car {
        top: 1%;
        left: 0;
        width: 8%;
        height: 16%;
        background-image: url('/assets/img/car-animation/Car.svg');
    }
    
    .play .car-animation-car {
        animation-name: car;
    }

    @keyframes car {
        0% {
            left: 0%;
        }
        100% {
            left: 64.5%;
        }
    }

    .car-animation-dot-left,
    .car-animation-dot-right {
        width: 3.278688525%;
        height: 12.65822785%;
        background-color: #fff;
        border-radius: 100%;
    }

    .car-animation-dot-left {
        left: 2.325%;
        top: 41%;
    }

    .car-animation-dot-right {
        right: 2.325%;
        top: 63%;
    }
