// Override Bootstrap default variables here
// Do not edit any of the files in /vendor/bootstrap/scss/!

// Color Variables
// Bootstrap Color Overrides

$gsk-primary: #F36633 !default;
$gsk-dark-grey: #544F40 !default;
$gsk-medium: #D5D1CE !default;

$gsk-purple: #BC1077 !default;
$gsk-red: #ED003C !default;
$gsk-indigo-blue: #40488D !default;
$gsk-green: #008A00 !default;
$gsk-teal: #15717D !default;
$gsk-yellow: #E49B13 !default;

$white: #FFFFFF !default;
$white-85: rgba(255, 255, 255, 0.85) !default;
$grey: #BEBEBD !default;
$black: #000000 !default;

$gsk-purple-60: rgba(188, 16, 119, 0.60) !default;
$gsk-teal-60: rgba(21, 113, 125, 0.60) !default;
$gsk-teal-30: rgba(21, 113, 125, 0.30) !default;

$gsk-green-60: #66B966 !default;
$gsk-red-60: #F4668A !default;
$link-blue: #0D76D1 !default;

$text-black: #2E2E3C !default;
$text-secondary: #777369 !default;
$text-medium: #878379 !default;
$text-light: #BBB9B3 !default;

$surface-dark: #BEBEBD !default;
$surface-medium: #EFEFED !default;
$surface-light: #FAFAF8 !default;

$gsk-lama: #E9A93D !default;
$gsk-laba: #F4668A !default;
$gsk-ics: #0D76D1 !default;
$gsk-dpi: #C5D34E !default;
$gsk-fmi: #60B0C9 !default;
$gsk-mdi: #747EB6 !default;
$gsk-lama-laba: #F5DB4A !default;
$gsk-ics-laba: #DDDF00 !default;
$gsk-isc-lama-laba: #8A347F !default;


$primary:       $gsk-primary !default;
$secondary:     $gsk-dark-grey !default;
$success:       $gsk-green !default;
$info:          $gsk-teal !default;
$warning:       $gsk-yellow !default;
$danger:        $gsk-red !default;


$landing-1: #914718 !default;
$landing-2: #FF6605 !default;


$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,

    "gsk-primary":    $gsk-primary,
    "gsk-dark-grey":  $gsk-dark-grey,
    "gsk-medium":     $gsk-medium,

    "gsk-purple":     $gsk-purple,
    "gsk-red":        $gsk-red,
    "gsk-indigo-blue":    $gsk-indigo-blue,
    "gsk-green":    $gsk-green,
    "gsk-teal":    $gsk-teal,
    "gsk-yellow":    $gsk-yellow,

    "white":    $white,
    "white-85":    $white-85,
    "grey":    $grey,
    "black":    $black,

    "gsk-purple-60":    $gsk-purple-60,
    "gsk-teal-60":    $gsk-teal-60,
    "gsk-teal-30":    $gsk-teal-30,
    "gsk-green-60":   $gsk-green-60,

    "link-blue":    $link-blue,

    "text-black":    $text-black,
    "text-secondary": $text-secondary,
    "text-medium":    $text-medium,
    "text-light":    $text-light,

    "surface-dark":    $surface-dark,
    "surface-medium":    $surface-medium,
    "surface-light":    $surface-light,

    "gsk-lama":    $gsk-lama,
    "gsk-laba":    $gsk-laba,
    "gsk-ics":    $gsk-ics,
    "gsk-dpi":    $gsk-dpi,
    "gsk-fmi":    $gsk-fmi,
    "gsk-mdi":    $gsk-mdi,
    "gsk-lama-laba":   $gsk-lama-laba,
    "gsk-ics-laba":    $gsk-green-60,
    "gsk-isc-lama-laba":    $gsk-isc-lama-laba,

    "landing-1":    $landing-1,
    "landing-2":    $landing-2,
  ),
  $theme-colors
);



// Custom Brand Colors
// $brand-google: #ea4335;
// $brand-facebook: #3b5998;

// Set Contrast Threshold
// $yiq-contrasted-threshold: 195 !default;

// Typography
// $font-size-base: 0.85rem !default;
//$line-height-base: 1.5 !default;

// $body-color: $gsk-dark-grey !default;
// $font-family: 'TypewriterSerial-Light', Arial, sans-serif !default;


// Shadows
// $box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($darkgrey, .2) !default;
// $box-shadow: 0 0.15rem 1.75rem 0 rgba($darkgrey, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Borders
$border-width: 1px;
//$border-radius: 0.35rem !default;
// $border-radius: 0 !default;
// $border-color: darken($grey, 2%);

// Spacing Variables
// Change below variable if the height of the navbar changes
// $topbar-base-height: 4.375rem;
// Change below variable to change the width of the sidenav
// $sidebar-base-width: 14rem;
// Change below variable to change the width of the sidenav when collapsed
// $sidebar-collapsed-width: 6.5rem;

// Card
// $card-cap-bg: $grey;
// $card-border-color: $border-color;

// Adjust column spacing for symmetry
// $spacer: 1rem;
$spacer: 8px !default;
$grid-gutter-width: $spacer * 1.0;

$spacers: () !default;
 $spacers: map-merge(
  (
     0: 0,
     0_5: ($spacer * .5),   //4px
     1: $spacer,            //8px
     1_5: ($spacer * 1.5),  //12px
     2: ($spacer * 2),      //16px
     3: ($spacer * 3),      //24px
     4: ($spacer * 4),      //32px
     5: ($spacer * 5),      //40px
     6: ($spacer * 6),      //48px
     7: ($spacer * 7),      //56px
     8: ($spacer * 8),      //64px
     9: ($spacer * 9),      //72px
     10: ($spacer * 10),    //80px
   ),
   $spacers
);

// Transitions
$transition-collapse: height .15s ease !default;

// Dropdowns
// $dropdown-font-size: 0.85rem;
// $dropdown-border-color: $border-color;

// Grid
$grid-columns:      12;
$grid-gutter-width: 32px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$container-max-widths: (
  sm: 100%, //540px
  md: 100%, //720px
  lg: 100%, //960px
  xl: 1176px //1140px
);

$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.5rem;
$tooltip-arrow-color: $gsk-dark-grey;
$tooltip-bg: $gsk-dark-grey;
