.team-member {
	img.team-member-photo {
		max-width: 120px;
		@include media-breakpoint-up(lg) {
			// max-width: none;
			max-width: 138px;
		}
	}
	p.team-member-name {
		font-family: 'akzidenz-grotesk-medium', arial, sans-serif;
		font-size: 20px;
		&::before {
				content: "";
				width: 120px;
				height: 4px;
				display: block;
				background-color: $gsk-primary;
				margin-bottom: 8px;
		}
	}
	p.team-member-role {
		font-size: 20px;
	}

	a.team-member-email {
		position: relative;
		padding-left: 1.5rem;
		&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 2.5px;
				width: 1.5rem;
				height: 10px;
				background-image: url('/assets/img/icons/icon_envelope.svg');
				background-repeat: no-repeat;
				background-position: left center;
				background-size: auto 10px;
				display: block;
		}
	}
	a.team-member-phone {
		position: relative;
		padding-left: 1.5rem;
		&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 1.5px;
				width: 1.5rem;
				height: 12.48px;
				background-image: url('/assets/img/icons/icon_mobile.svg');
				background-repeat: no-repeat;
				background-position: left center;
				background-size: auto 12.48px;
				display: block;
		}
	}
}
