.hero-bg-wrapper {
	background-image: url("/uploads/landing-5/living-gradient.png");
	background-repeat: no-repeat;
	background-size: cover;
	border-bottom: 1px solid #EFEFED;

	@media (min-width: 768px) and (max-width: 991.98px) {
		.container .row {
			margin-right: -24px;
			margin-left: -24px;

			> div {
				padding-left: 24px;
				padding-right: 24px;
			}
		}
	}

	.bg-pointer {
		background-image: url("/uploads/landing-5/point-down.svg");
		background-position: top center;
		background-size: 100px;
		background-repeat: no-repeat;
		@include media-breakpoint-up(lg) {
			background-image: url("/uploads/landing-5/point-right.svg");
			background-position: left center;
		}
	}

	.hero-bg {
		@include media-breakpoint-up(lg) {
			background: linear-gradient(to right, white 0%, white 28%, transparent 28%, transparent 100%);
		}
		@include media-breakpoint-up(xl) {
			background: linear-gradient(to right, white 0%, white 40%, transparent 40%, transparent 100%);
		}
	}
}

ul.ticklist {
	list-style: none;
	position: relative;
	padding-left: 1.5rem;

	li {
		padding-left: 0.825rem;
		padding-bottom: 0.75rem;

		&:before {
			position: absolute;
			left: 0;
			content: '\2714';
			color: $primary;
			font-size: 2.0rem;
		}
	}
}

img.listicon {
	width: 150px;
	@include media-breakpoint-up(md) {
		width: 200px;
	}
}
