.modal-lg {
	max-width: 720px;
}

.modal-xl {
  max-width: 970px;
}

.modal-xxl {
  max-width: 1200px;
}

.modal-content, .modal-header, .modal-footer {
	border-style: solid;
	border-color: transparent;
}

.modal-header {
   padding-bottom: 0;
   border-bottom: 0;
   display: block !important;
}

.modal-header .modal-title {
	margin-left: auto;
	margin-right: auto;
}

h2.modal-title {
	// font-size: 1.75em;
	// line-height: 1.4;
	text-align: center;
	display: block;
}

.modal-body {
  border-left-style: solid;
	border-left-color: transparent;
	border-right-style: solid;
	border-right-color: transparent;
}


.modal-footer {
    padding: 1rem;
    border-top: 0;
    display: block;
    align-items: center;
    justify-content: center;
}

.modal-footer p {
	display: inline-block;
	clear: both;
}


.modal-footer > :not(:first-child) {
    margin-left: auto;
}

.modal-footer > :not(:last-child) {
    margin-right: auto;
}


.modal-backdrop.show {
    opacity: 1.0;
}

.modal-backdrop.modal-backdrop-black.show {
    opacity: 1.0;
}


.modal-backdrop {
    background-color: #000;
}
