#print-header {
	display: none;
}


#print-footer {
	display: none;
}

// @page {
// size: A4;
// margin: 0;
// }

@media print {


	html {
		position: relative;
		min-height: none;
		overflow-y: auto;
	}

	body {
		height: auto;
	}

* {
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
	}


.print-page-break {
	break-before: always;
	page-break-before: always;
	height: 120px; //adds padding to top of the page to solve overlapping content
}


#header-prescribinginfo  {
	display: none !important;
}

#print-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	display: block;

	p.small {
		font-size: 12px;
		line-height: 16px;
	}

	img#print-header-logo {
		display: block;
		float: left;
		width: 40px;
		height: auto;
		margin-left: 0.5rem;
		margin-right: 0.75rem;
	}

	#print-header-main {
		display: inline-block;
		padding-top: 2px;
	}

	#print-header-side {
		display: block;
		float: right;
		width: 100px;
		padding-top: 2px;
	}
}


#print-footer {

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
  z-index: 9999;
	display: block;
	// height: 56px;

	p.small {
		font-size: 12px;
		line-height: 16px;
	}
}


}
