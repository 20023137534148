#landing-2-hero-image {

	 @include media-breakpoint-up(sm) {
max-width: 470px;
	 }
	 @include media-breakpoint-up(md) {
		 max-width: 600px;
		 float: right;
	 }
	 @include media-breakpoint-up(lg) {
		 max-width: 470px;
		 margin-left: -280px;
		 margin-top: 20px;
		 margin-bottom: -120px;
	 }
}

.landing-2-content {
	margin-top: 50px;
	margin-bottom: 0;
}

.bg-landing-2-image-shadow {
	background-image: url("/uploads/landing-2/landing2_bg_sm.jpg");
	background-position: top center;
	background-size: 100%;
	background-repeat: no-repeat;
}

.bg-landing-2-image-shadow-2 {
	background-image: url("/uploads/landing-2/landing2_bg_sm.jpg");
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
		@include media-breakpoint-up(xl) {
				background-color: #B55A23
		}
}


.landing-2-content-2 {
	margin-top: 300px;
	// @include media-breakpoint-up(sm) {
	// 	  margin-top: 10vw;
	// }
	@include media-breakpoint-up(md) {
		  margin-top: 30vw;
	}
	@include media-breakpoint-up(lg) {
		  margin-top: 30vw;
	}
	@include media-breakpoint-up(xl) {
		  margin-top: 30vw;
	}
}

.bg-landing-2-image-shadow-3 {
	background-image: url("/uploads/landing-2/landing-bg-small_shadow.jpg");
	background-position: top center;
	background-size: 100%;
	background-repeat: no-repeat;
		@include media-breakpoint-up(md) {
				background-image: url("/uploads/landing-2/landing-bg-large_shadow.jpg");
		}
}

.bg-landing-2-col {
	 background-color: #B55A23
}
