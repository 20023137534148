.nav-tabs {
	border: none;
	margin-bottom: 1rem;

	.nav-link {
		margin-bottom: -2px;
		border: none;
		border-left: 0.5px solid $surface-medium;
		border-bottom: 2px solid $surface-medium;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		color: $text-medium;
		background-color: $white;
	}

	.nav-item:first-child .nav-link {
		border-left: none;
	}

	.nav-item.show .nav-link,
	.nav-link.active,
	.nav-link:focus,
	.nav-link:hover {
		border-bottom: 2px solid $gsk-teal;
		color: $gsk-teal;
	}
}
