.columns-md-2 {
	column-count: 1;
	column-gap: 1rem;
	@include media-breakpoint-up(md) {
		column-count: 2;
	}
}

.footnotes.columns-md-2 {
	column-count: 1;
	column-gap: 2rem;
	@include media-breakpoint-up(md) {
		column-count: 2;
	}
}

.footnotes {
	margin: 0;
	padding: 0 1rem;
}

.footnotes li {
	break-inside: avoid;
	margin-top: 0;
}
